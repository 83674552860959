@layer components {
  /* Heading + Fine Print Components */

  .fine-print,
  .graytwocolumnform,
  .redonecolumnform,
  .redtwocolumnform {
    @apply space-y-4 text-center text-xl leading-6 lg:mb-4 lg:space-y-8;
  }

  .grayonecolumnform {
    @apply space-y-4 text-center text-xl leading-6 lg:mb-10 lg:space-y-8;
  }

  .fine-print a,
  .graytwocolumnform a,
  .grayonecolumnform a {
    @apply text-pr-red-500 underline;
  }

  .fine-print-get-started a {
    @apply text-[#787779] underline;
  }

  .redonecolumnform a,
  .redtwocolumnform a {
    @apply text-pr-dark-gray-500 underline;
  }

  /* Form wrapper Components */
  .form-container {
    @apply relative inline-flex w-full min-w-0 flex-col p-0 align-top;
  }

  .form-input-wrapper {
    @apply m-0 text-xl leading-none;

    font-family: inherit;
    -webkit-tap-highlight-color: transparent;
    color: rgb(0 0 0 / 87%);
  }

  /* Blog Components */
  .blog-label-wrapper {
    @apply mb-2.5 text-xs font-normal uppercase leading-none;

    letter-spacing: 0.0094em;
  }

  .blog-form-button {
    @apply m-0 block appearance-none pb-3 text-base font-normal uppercase leading-none;

    transition-duration: 0.3s;
  }

  /* Product Components */
  .product-form-wrapper {
    @apply m-0 leading-[1.3] text-pr-dark-gray-500 accent-pr-teal-600;
  }

  .product-form-label-wrapper {
    @apply mb-2.5 font-normal leading-4;
  }

  /* prettier-ignore */
  .product-button {
    @apply inline-flex cursor-pointer gap-3 px-9 py-3.5 text-center text-pr-lg text-white;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    border-radius: 9999px;
    font-family: inherit;
  }

  /* Shared Blog Styling */
  .gray-form,
  .red-form {
    @apply my-5 w-full;
  }

  .gray-form .mktoForm .mktoFieldWrap select,
  .red-form .mktoForm .mktoFieldWrap select,
  .inline-form .mktoForm .mktoFieldWrap select {
    appearance: none;
  }

  .gray-form .mktoForm .mktoRadioList input,
  .red-form .mktoForm .mktoRadioList input {
    @apply mr-2 inline;
  }

  .gray-form .mktoForm .mktoFieldWrap .mktoRadioList label::after,
  .red-form .mktoForm .mktoFieldWrap .mktoRadioList label::after {
    content: "\A";
    white-space: pre;
  }

  /* Shared Styling Product Forms */

  .one-col-form .mktoForm .mktoFieldWrap .mktoRadioList label::after,
  .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList label::after {
    content: "\A";
    white-space: pre;
  }

  /* Shared Styling All Forms */
  .gray-form .mktoForm .mktoFieldWrap .mktoLabel,
  .red-form .mktoForm .mktoFieldWrap .mktoLabel,
  .one-col-form .mktoForm .mktoFieldWrap .mktoLabel {
    @apply flex;
  }

  .gray-form .mktoForm .mktoFieldWrap .mktoLabel .mktoAsterix,
  .red-form .mktoForm .mktoFieldWrap .mktoLabel .mktoAsterix,
  .one-col-form .mktoForm .mktoFieldWrap .mktoLabel .mktoAsterix {
    @apply order-last;
  }

  /* Red theme */
  .red-form .mktoForm .mktoFieldWrap {
    @apply form-container mb-6 mt-0;
  }

  .red-form .mktoForm .mktoFieldWrap label {
    @apply blog-label-wrapper text-white;
  }

  .red-form .mktoForm input,
  .red-form .mktoForm select {
    @apply form-input-wrapper block px-4 py-3.5;
  }

  .red-form .mktoForm .mktoFieldWrap input:focus,
  .red-form .mktoForm .mktoFieldWrap select:focus {
    outline: none;
    border-color: #6fd4db;
  }

  .red-form .mktoForm button {
    @apply blog-form-button bg-white px-7 py-5 text-pr-red-500;
  }

  .red-form .mktoForm button:hover,
  .red-form .mktoForm button:focus {
    @apply bg-white text-pr-red-500;
  }

  .red-form .mktoForm .mktoErrorMsg {
    @apply mb-0 text-white;
  }

  @media (min-width: 1065px) {
    .red-two-col .mktoForm {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 3rem;
    }

    .red-two-col .mktoForm .mktoFormRow:has(.mktoTextField) {
      grid-column: span 1 / span 1;
    }

    .red-two-col .mktoForm .mktoFormRow:has(.mktoRadioList),
    .red-two-col .mktoForm .mktoErrorMsg {
      grid-column: span 2 / span 2;
    }
  }

  @media (min-width: 563px) and (max-width: 765px) {
    .red-two-col .mktoForm {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 3rem;

      /* padding: 0 2rem 1.5rem; */
    }

    .red-two-col .mktoForm .mktoFormRow:has(.mktoTextField) {
      grid-column: span 1 / span 1;
    }

    .red-two-col .mktoForm .mktoFormRow:has(.mktoRadioList),
    .red-two-col .mktoForm .mktoErrorMsg {
      grid-column: span 2 / span 2;
    }
  }

  .red-two-col .mktoButtonRow {
    @apply col-span-full;
  }

  /* Inline Theme */

  .inline-form {
    @apply w-full;
  }

  .inline-form .mktoForm .mktoFieldWrap {
    @apply form-container mb-0 mt-0;
  }

  .inline-form .mktoForm .mktoFieldWrap label {
    @apply blog-label-wrapper text-white;
  }

  .inline-form .mktoForm input,
  .inline-form .mktoForm select {
    @apply form-input-wrapper block h-[46px] px-4 py-0;
  }

  .inline-form .mktoForm .mktoFormRow {
    min-width: 0;
  }

  .inline-form .mktoForm .mktoFieldWrap input:focus,
  .inline-form .mktoForm .mktoFieldWrap select:focus {
    outline: none;
    border-color: #6fd4db;
  }

  .inline-form .mktoForm {
    display: flex;
    position: relative;
    align-items: flex-end;
  }

  .inline-form .mktoForm button {
    @apply blog-form-button relative m-0 h-[46px] bg-white px-6 py-0 text-pr-red-500;
  }

  .inline-form .mktoForm button:hover,
  .inline-form .mktoForm button:focus {
    @apply bg-white text-pr-dark-gray-500;
  }

  .inline-form .mktoForm .mktoErrorMsg {
    @apply mb-0 pb-14 text-white;
  }

  .inline-form .mktoForm .mktoFieldWrap .mktoLabel .mktoAsterix {
    display: none;
  }

  /* Two-Col Theme */

  .two-col-form {
    @apply product-form-wrapper text-xl;
  }

  .two-col-form .mktoForm .mktoFieldWrap {
    @apply form-container;

    margin: 1rem 0 2.25rem;
  }

  .two-col-form .mktoForm .mktoFieldWrap label {
    @apply product-form-label-wrapper;
  }

  .two-col-form .mktoForm .mktoFieldWrap input,
  .two-col-form .mktoForm .mktoFieldWrap select,
  .two-col-form .mktoForm .mktoFieldWrap textarea {
    @apply form-input-wrapper;

    width: auto !important;
    border: 0;
    padding: 0.25rem 0.75rem;
    border-bottom: #30323b solid 1px;
    font-size: 1.125rem;
  }

  .two-col-form .mktoForm .mktoFieldWrap input::placeholder,
  .two-col-form .mktoForm .mktoFieldWrap textarea::placeholder,
  .two-col-form .mktoForm .mktoFieldWrap select {
    color: rgb(48 50 59 / 65%);
  }

  .two-col-form .mktoForm .mktoFieldWrap:has(textarea) {
    margin-top: 0;
  }

  .two-col-form .mktoForm .mktoFieldWrap select {
    padding: 0.5rem 2rem 0.5rem 0.5rem;
  }

  .two-col-form .mktoForm input[type="checkbox"],
  .two-col-form .mktoForm input[type="radio"] {
    margin: 8px;
  }

  .two-col-form .mktoForm .mktoFieldWrap input:focus,
  .two-col-form .mktoForm .mktoFieldWrap select:focus,
  .two-col-form .mktoForm .mktoFieldWrap textarea:focus {
    outline: none;
  }

  .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList,
  .two-col-form .form .mktoFieldWrap .mktoCheckboxList {
    display: block;
  }

  .two-col-form .mktoForm button {
    @apply product-button;

    background-color: #19a4ad;
    margin: 0;
  }
  /* stylelint-disable selector-id-pattern */

  .two-col-form .mktoButtonRow {
    @apply col-span-full;
  }

  .two-col-form .mktoForm button:hover,
  .two-col-form .mktoForm button:focus {
    background-color: #30323b;
  }

  .two-col-form .mktoForm .mktoErrorMsg {
    @apply error-message;
  }

  .two-col-form .mktoForm {
    padding-bottom: 1.5rem;
  }

  .two-col-form .mktoForm .mktoFieldWrap .mktoLabel .mktoAsterix {
    display: none;
  }

  .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList > input,
  .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList > label {
    display: inline-block !important;
    margin-right: 0.5em !important;
  }

  .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList > label {
    margin-right: 2em !important;
  }

  .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList input[type="radio"] {
    float: none;
  }

  .two-col-form .mktoForm .mktoFormRow:has(.mktoTextField) label,
  .two-col-form .mktoForm .mktoFormRow:has(textarea) label,
  .two-col-form .mktoForm .mktoFormRow:has(.mktoEmailField) label,
  .two-col-form .mktoForm .mktoFormRow:has(#LblEmail) label,
  .two-col-form .mktoForm .mktoFormRow:has(#LblPhone) label {
    display: none;
  }

  /* prettier-ignore */
  .two-col-form .mktoForm .mktoFormRow:has(#LblmktoPreferenceContentNewsletters) {
    @apply col-span-2;
  }

  /* prettier-ignore */
  .two-col-form .mktoForm .mktoFieldWrap:has(#LblmktoPreferenceContentNewsletters) {
    @apply flex flex-row;
  }

  /* prettier-ignore */
  .two-col-form .mktoForm .mktoFieldWrap:has(#LblmktoPreferenceContentNewsletters) label {
    @apply order-2;
  }

  .two-col-form .mktoForm .mktoFormRow:has(.mktoPlaceholder) {
    @apply hidden;
  }

  .two-col-form .mktoForm .mktoFormRow .mktoFieldDescriptor {
    @apply !mb-0;
  }

  .two-col-form .mktoForm .mktoFormRow {
    @apply self-end;
  }

  @media (min-width: 768px) and (max-width: 1082px) {
    .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList > input,
    .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList > label {
      display: inline !important;
      margin-right: 0.5em !important;
    }
  }

  @media (max-width: 510px) {
    .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList > input,
    .two-col-form .mktoForm .mktoFieldWrap .mktoRadioList > label {
      display: inline !important;
      margin-right: 0.5em !important;
    }
  }

  @media (min-width: 1065px) {
    .two-col-form .mktoForm {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 3rem;
    }

    .two-col-form .mktoForm .mktoFormRow:has(.mktoTextField) {
      grid-column: span 1 / span 1;
    }

    .two-col-form .mktoForm .mktoFormRow:has(textarea) {
      grid-column-start: 1;
    }

    .two-col-form .mktoForm .mktoFormRow:has(.mktoRadioList),
    .two-col-form .mktoForm .mktoErrorMsg {
      grid-column: span 2 / span 2;
    }

    .two-col-form .thank-you {
      border-radius: 1.5rem;
      grid-column: span 2 / span 2;
      margin: 0 -2rem -1.5rem;
    }
  }

  @media (min-width: 563px) and (max-width: 765px) {
    .two-col-form .mktoForm {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 3rem;
    }

    .two-col-form .mktoForm .mktoFormRow:has(.mktoTextField) {
      grid-column: span 1 / span 1;
    }

    .two-col-form .mktoForm .mktoFormRow:has(.mktoRadioList),
    .two-col-form .mktoForm .mktoFormRow:has(textarea),
    .two-col-form .mktoForm .mktoErrorMsg {
      grid-column: span 2 / span 2;
    }

    .two-col-form .thank-you {
      border-radius: 1.5rem;
      grid-column: span 2 / span 2;
      margin: 0 -2rem -1.5rem;
    }
  }

  .gray-two-col .mktoForm input,
  .gray-two-col .mktoForm select {
    @apply form-input-wrapper;

    padding: 0.75rem 1rem !important;
    border: #73738c solid 1px !important;
    border-radius: 2px;
  }

  .gray-two-col .mktoForm input:focus,
  .gray-two-col .mktoForm select:focus {
    outline: none;
    border-color: #babcc1;
  }

  /* One Col Theme */

  .one-col-form {
    @apply product-form-wrapper text-lg;
  }

  .one-col-form .mktoForm .mktoFieldWrap {
    @apply form-container;

    margin: 0 0 24px;
  }

  .one-col-form .mktoForm .mktoFieldWrap label {
    @apply product-form-label-wrapper text-pr-dark-gray-400;
  }

  .one-col-form .mktoForm .mktoLabel {
    @apply flex;
  }

  .one-col-form .mktoForm input,
  .one-col-form .mktoForm textarea,
  .one-col-form .mktoForm select {
    @apply form-input-wrapper;

    padding: 0.75rem 1rem;
    font-family: inherit;
    line-height: 1;
    border: 0;
    border-bottom: #30323b solid 1px;
    font-size: 1.125rem;
  }

  .one-col-form .mktoForm input[type="checkbox"],
  .one-col-form .mktoForm input[type="radio"] {
    @apply m-2;
  }

  .one-col-form .mktoForm .mktoFieldWrap select {
    appearance: none;
  }

  .one-col-form .mktoForm .mktoFieldWrap input:focus,
  .one-col-form .mktoForm .mktoFieldWrap textarea:focus,
  .one-col-form .mktoForm .mktoFieldWrap select:focus {
    outline: none;
    border-color: #babcc1;
  }

  .one-col-form .mktoForm button {
    @apply product-button bg-pr-red-500;

    margin: 1.5rem 0;
  }

  .one-col-form .mktoForm button:hover,
  .one-col-form .mktoForm button:focus {
    @apply bg-pr-dark-gray-500;
  }

  .one-col-form .gray-button .mktoForm button {
    @apply button-text-red;
  }

  .error-message {
    @apply p-2 text-pr-red-500;
  }

  .one-col-form .mktoForm .mktoErrorMsg {
    @apply error-message mb-0;
  }

  .one-col-form .mktoForm .mktoFieldWrap .mktoLabel .mktoAsterix {
    @apply hidden;
  }

  /* Get Started Form */
  .get-started-form .mktoForm {
    width: 100%;
  }

  .get-started-form .mktoForm .mktoFieldWrap input,
  .get-started-form .mktoForm .mktoFieldWrap select {
    @apply bg-transparent;
  }

  .gated-content-form .mktoForm button {
    @apply product-button;

    background-color: #19a4ad;
    margin: 0;
  }

  .gated-content-form .mktoForm .mktoFieldWrap {
    @apply w-full md:w-[75%] lg:w-[50%];
  }

  .onecolumnform-bluebutton {
    @apply w-full;
  }

  .onecolumnform-bluebutton .mktoForm button {
    @apply !my-1;

    background-color: #19a4ad;
  }

  .onecolumnform-bluebutton .mktoForm {
    @apply h-[175px];
  }

  .onecolumnform-bluebutton .mktoForm .mktoFieldWrap input::placeholder,
  .onecolumnform-bluebutton .mktoForm .mktoFieldWrap textarea::placeholder,
  .onecolumnform-bluebutton .mktoFieldWrap select {
    color: rgb(48 50 59 / 65%);
  }

  .onecolumnform-bluebutton .mktoForm .mktoFieldWrap {
    @apply relative;
  }

  .onecolumnform-bluebutton .mktoForm .mktoErrorMsg {
    @apply error-message !mb-0 text-[15px];
  }
}
