@layer components {
  .bentocore-center,
  .bentocore-module {
    @apply cursor-pointer;
  }

  .bentocore-module__icon {
    @apply fill-ada-salmon-500 transition duration-300;
  }

  .bentocore-module__petal {
    @apply fill-pr-yellow-200 stroke-ada-salmon-500 stroke-1 transition duration-300;
  }

  .bentocore-module:hover .bentocore-module__icon {
    @apply fill-pr-yellow-200;
  }

  .bentocore-module:hover .bentocore-module__petal {
    @apply fill-ada-salmon-500;
  }

  .bentocore-module__petal .bentocore-module--active {
    @apply fill-ada-salmon-500 transition duration-300;
  }

  .bentocore-module__icon .bentocore-module--active {
    @apply fill-pr-yellow-200 transition duration-300;
  }

  .bentocore-center .bentocore-center__circles > :first-child {
    @apply fill-pr-dark-gray-300 transition duration-300;
  }

  .bentocore-center .bentocore-center__circles > :last-child {
    @apply fill-pr-dark-gray-400 transition duration-300;
  }

  .bentocore-center:hover .bentocore-center__circles > :first-child {
    @apply fill-pr-dark-gray-400;
  }

  .bentocore-center:hover .bentocore-center__circles > :last-child {
    @apply fill-pr-dark-gray-600;
  }
}
