@font-face {
  font-display: optional;
  font-family: CircularStd;
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/CircularStd-Black.otf") format("opentype");
}

@font-face {
  font-display: optional;
  font-family: CircularStd;
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/CircularStd-BlackItalic.otf") format("opentype");
}

@font-face {
  font-display: optional;
  font-family: CircularStd;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/CircularStd-Bold.otf") format("opentype");
}

@font-face {
  font-display: optional;
  font-family: CircularStd;
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/CircularStd-BoldItalic.otf") format("opentype");
}

@font-face {
  font-display: optional;
  font-family: CircularStd;
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/CircularStd-Medium.otf") format("opentype");
}

@font-face {
  font-display: optional;
  font-family: CircularStd;
  font-style: italic;
  font-weight: 500;
  src: url("/fonts/CircularStd-MediumItalic.otf") format("opentype");
}

@font-face {
  font-display: optional;
  font-family: CircularStd;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/CircularStd-Book.otf") format("opentype");
}

@font-face {
  font-display: optional;
  font-family: CircularStd;
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/CircularStd-BookItalic.otf") format("opentype");
}
