@layer components {
  .type-alpha {
    @apply text-3xl font-bold leading-snug tracking-tight md:text-5xl lg:text-6xl;
  }

  .type-beta {
    @apply text-2xl font-bold leading-tight tracking-tightest lg:text-5xl;
  }

  .type-gamma {
    @apply text-xl font-medium leading-snug tracking-tighter lg:text-4xl;
  }

  .type-delta {
    @apply text-lg font-bold leading-snug lg:text-3xl;
  }

  .type-epsilon {
    @apply text-lg font-medium leading-snug;
  }

  .type-zeta {
    @apply text-2xs uppercase tracking-wide md:text-base;
  }

  .type-eta {
    @apply text-2xs font-medium uppercase md:text-xs;
  }

  .prose:not(.prose-pr) h1 {
    @apply type-alpha;
  }

  .prose:not(.prose-pr) h2 {
    @apply type-beta;
  }

  .prose:not(.prose-pr) h3 {
    @apply type-gamma;
  }

  .prose:not(.prose-pr) h4 {
    @apply type-delta;
  }

  .prose:not(.prose-pr) h5 {
    @apply type-epsilon;
  }

  .prose:not(.prose-pr) h6 {
    @apply type-zeta;
  }

  .prose:not(.prose-pr) a {
    @apply link-underline-animated-sm;
  }

  /* @TODO: most of these need mobile sizes */

  .type-pr-alpha {
    @apply text-pr-9xl font-bold leading-pr-tighter tracking-pr-tighter lg:text-pr-10xl lg:leading-pr-tightest xl:text-pr-12xl 4xl:text-pr-13xl;
  }

  .type-pr-beta {
    @apply text-pr-6xl font-bold leading-pr-tighter tracking-pr-tighter lg:text-pr-9xl xl:text-pr-11xl;
  }

  .type-pr-gamma {
    @apply text-pr-6xl font-bold leading-pr-relaxed tracking-pr-tighter lg:text-pr-8xl xl:text-pr-10xl;
  }

  .type-pr-delta {
    @apply text-pr-3xl leading-pr-relaxed md:tracking-pr-tighter lg:text-pr-5xl xl:text-pr-8xl;
  }

  .type-pr-epsilon {
    @apply text-pr-lg leading-snug md:tracking-pr-tighter lg:text-pr-xl xl:text-pr-2xl;
  }

  .type-pr-zeta {
    @apply leading-pr-loose md:text-pr-xl md:leading-pr-looser;
  }

  .type-pr-eta {
    @apply text-pr-sm leading-pr-loose md:text-pr-xl md:leading-pr-looser;
  }

  .type-pr-theta {
    @apply text-pr-xs leading-pr-loose md:text-pr-lg md:leading-pr-looser;
  }

  .type-pr-alpha a,
  .type-pr-beta a,
  .type-pr-gamma a,
  .type-pr-delta a {
    @apply link-underline-animated-lg text-pr-red-600;
  }

  .type-pr-epsilon a {
    @apply link-underline-animated-sm md:link-underline-animated-lg text-pr-red-600;
  }

  .type-pr-zeta a,
  .type-pr-eta a,
  .type-pr-theta a {
    @apply link-underline-animated-sm text-pr-red-600;
  }

  .type-pr-zeta ul,
  .type-pr-eta ul,
  .type-pr-theta ul {
    @apply mb-4 mt-10 inline-block list-disc pl-4 text-left lg:mb-5 lg:mt-[50px] lg:pl-[30px];
  }

  .type-pr-zeta ul li,
  .type-pr-eta ul li,
  .type-pr-theta ul li {
    @apply mb-2 mt-4;
  }

  .prose-pr table {
    @apply block overflow-x-auto;
  }

  .prose-pr a {
    @apply link-underline-animated-sm;
  }

  .quote-pr > * {
    quotes: "\201C""\201D""\2018""\2019";
  }

  .quote-pr > *::before {
    content: open-quote;

    @apply absolute -left-[7px] md:-left-2.5 lg:-left-3 xl:-left-4;
  }

  .quote-pr > *::after {
    content: close-quote;
  }

  .statistic {
    @apply lg:leading-[50px];
  }

  .statistic > p {
    @apply text-[12px] font-bold lg:text-[24px];
  }

  .statistic > p > b {
    @apply block text-[32px] font-bold text-pr-red-500 lg:text-[63px];
  }

  sub {
    bottom: 0;
  }
}
