@layer components {
  .blog-layout {
    /* 16 * .9 */

    /* font-size: 14.4px; */
    @apply leading-normal text-gray-500;
  }

  .product-layout {
    @apply leading-normal text-pr-dark-gray-500;
  }
}

@layer utilities {
  .stroke-butt {
    stroke-linecap: butt;
  }

  .stroke-round {
    stroke-linecap: round;
  }

  .stroke-square {
    stroke-linecap: square;
  }
}

body .calendly-overlay {
  background-color: rgb(0 0 0 / 30%);
}

body .calendly-overlay .calendly-popup-close {
  top: 2rem;
  right: 2rem;
  height: 1.5rem;
  width: 1.5rem;
  background-image: url("/img/icons/close-white.svg");
}
