@layer components {
  .toad-calc-input {
    @apply w-full border-b-[1px] border-pr-dark-gray-500 bg-transparent text-xl text-pr-dark-gray-500 placeholder:text-[#777] focus:ring-1 focus:ring-pr-yellow-500;
  }

  .toad-calc-input-error {
    @apply w-full border-b-[1px] border-[#DC2710] bg-transparent text-xl text-[#DC2710] placeholder:text-[#DC2710] focus:ring-1 focus:ring-pr-yellow-500;
  }

  .toad-calc-selected > select {
    appearance: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTMgOCIgZmlsbD0ibm9uZSI+CjxwYXRoIGQ9Ik02LjE0MTc2IDcuNjIxMDdMMC4xODE3NjMgMS42MjEwN0wxLjU3MjQzIDAuMjIxMDY5TDYuMTQxNzYgNC44MjEwN0wxMC43MTExIDAuMjIxMDY5TDEyLjEwMTggMS42MjEwN0w2LjE0MTc2IDcuNjIxMDdaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4=");
    background-repeat: no-repeat, repeat;
    background-position: right 0.5em top 50%, 0 0;
    background-size: 0.75em auto, 100%;
  }

  .toad-calc-selected-error > select {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGlkPSJEcm9wZG93biBBcnJvdyIgZD0iTTYuMTc5NzMgNy41NTE0OUwwLjIxOTcyNyAxLjU1MTQ5TDEuNjEwMzkgMC4xNTE0ODlMNi4xNzk3MyA0Ljc1MTQ5TDEwLjc0OTEgMC4xNTE0ODlMMTIuMTM5NyAxLjU1MTQ5TDYuMTc5NzMgNy41NTE0OVoiIGZpbGw9IiNEQzI3MTAiLz4KPC9zdmc+Cg==");
  }

  .toad-calc-selected > input[type="checkbox"] {
    position: relative;
    cursor: pointer;
  }

  .toad-calc-selected > input[type="checkbox"]::before {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border: 1px solid #787779;
    border-radius: 0;
    background-color: #f0ebe5;
  }

  .toad-calc-selected > input[type="checkbox"]:checked::after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 6px;
  }
}
