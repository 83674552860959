/* stylelint-disable */
@layer components {
  /* buttons */

  .button {
    @apply inline-block border px-10 py-4 text-center text-lg uppercase tracking-wide transition lg:px-7 lg:text-base;
  }

  .button-solid-red {
    @apply button border-red-600 bg-red-600 text-white hover:border-red-700 hover:bg-red-700 focus:border-red-700 focus:bg-red-700;
  }

  .button-solid-black {
    @apply button border-gray-500 bg-gray-500 text-white hover:border-gray-700 hover:bg-gray-700 focus:border-gray-700 focus:bg-gray-700;
  }

  .button-text-red {
    @apply button border-white bg-white text-red-500 hover:text-gray-500 focus:text-gray-500;
  }

  .button-text-black {
    @apply button border-white bg-white text-gray-500 hover:text-red-500 focus:text-red-500;
  }

  /* links */

  .link-underline-animated {
    @apply inline bg-no-repeat duration-500;

    background-image: linear-gradient(currentcolor, currentcolor);
    background-position: 0% 100%;
    transition-property: background-size;
    background-size: 0% var(--border-width);
  }

  .link-underline-animated:hover,
  .link-underline-animated:focus,
  .group:hover .link-underline-animated,
  .group:focus .link-underline-animated,
  .group-hover:hover .link-underline-animated {
    background-size: 100% var(--border-width);
  }

  .link-underline-animated-sm {
    @apply link-underline-animated;

    --border-width: 1px;
  }

  .link-underline-animated-lg {
    @apply link-underline-animated;

    --border-width: 2px;
  }

  /* .link-underline-animated-pr-red-200 {
    @apply link-underline-animated;
    background-image: linear-gradient(theme("colors.pr-red.200"), theme("colors.pr-red.200"));

    --border-width: 2px;
  }   */

  /* .link-underline {
    @apply duration-500 ease-in-out;

    text-decoration-style: initial;
    transition-property: text-decoration-color;
  }

  .link-underline-on {
    @apply link-underline underline decoration-transparent decoration-2 hover:decoration-current focus:decoration-current;
  } */

  /* plyr */

  .blog-layout {
    --plyr-color-main: theme("colors.red.500");
    --plyr-control-radius: theme("borderRadius.full");
    --plyr-control-padding: theme("spacing.3");
    --plyr-control-icon-size: theme("spacing.5");
  }

  .blog-layout .plyr .plyr__controls {
    @apply justify-start px-4 pb-4 lg:px-6 lg:pb-6 !important;
  }

  .blog-layout .plyr .plyr__controls .plyr__controls__item:first-child {
    @apply mr-0;
  }

  .blog-layout .plyr .plyr__controls__item.plyr__control {
    @apply h-[38px] w-[38px] duration-100 !important;
  }

  .blog-layout .plyr.plyr--paused .plyr__progress__container,
  .blog-layout .plyr.plyr--paused .plyr__volume,
  .blog-layout .plyr.plyr--paused [data-plyr="fullscreen"] {
    @apply pointer-events-none opacity-0 duration-100 !important;
  }

  .blog-layout .plyr .icon--not-pressed,
  .blog-layout .plyr .icon--pressed {
    @apply mx-auto;
  }

  .blog-layout .plyr [data-plyr="play"] {
    @apply bg-center bg-no-repeat !important;

    background-image: url("data:image/svg+xml,%3Csvg width='41' height='40' viewBox='0 0 41 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.2344 0C9.23438 0 0.234375 9 0.234375 20C0.234375 31 9.23438 40 20.2344 40C31.2344 40 40.2344 31 40.2344 20C40.2344 9 31.2344 0 20.2344 0ZM29.2344 22L17.6344 29.6C16.2344 30.6 14.2344 29.4 14.2344 27.6V12.4C14.2344 10.6 16.2344 9.4 17.6344 10.4L29.2344 18C30.6344 19 30.6344 21 29.2344 22Z' fill='%23F15541'/%3E%3C/svg%3E") !important;
  }

  .blog-layout .plyr [data-plyr="play"].plyr__control--pressed {
    background-color: var(--plyr-color-main) !important;
  }

  .blog-layout .plyr [data-plyr="play"]:not(.plyr__control--pressed):hover {
    @apply bg-white !important;
  }

  .blog-layout .plyr [data-plyr="play"] .icon--not-pressed {
    @apply invisible;
  }

  .product-layout {
    --plyr-color-main: theme("colors.pr-teal.600");
    --plyr-control-radius: theme("borderRadius.full");
    --plyr-control-padding: theme("spacing.3");
    --plyr-control-icon-size: theme("spacing.5");
  }

  .product-layout .plyr .plyr__controls {
    @apply justify-start px-4 pb-4 lg:px-6 lg:pb-6 !important;
  }

  .product-layout .plyr .plyr__controls .plyr__controls__item:first-child {
    @apply mr-0;
  }

  .product-layout .plyr .plyr__controls__item.plyr__control {
    @apply duration-100 !important;
  }

  .product-layout .plyr.plyr--paused .plyr__progress__container,
  .product-layout .plyr.plyr--paused .plyr__volume,
  .product-layout .plyr.plyr--paused .plyr__time,
  .product-layout .plyr.plyr--paused [data-plyr="fullscreen"] {
    @apply pointer-events-none opacity-0 duration-100 !important;
  }

  .product-layout .plyr .icon--not-pressed,
  .product-layout .plyr .icon--pressed {
    @apply mx-auto;
  }

  .product-layout .plyr [data-plyr="play"] {
    @apply size-12 bg-center bg-no-repeat md:size-28 !important;

    background-color: var(--plyr-color-main) !important;
  }

  .product-layout .plyr [data-plyr="play"] .icon--pressed,
  .product-layout .plyr [data-plyr="play"] .icon--not-pressed {
    @apply size-7 md:size-14;
  }

  .product-layout .plyr [data-plyr="play"] .icon--not-pressed {
    @apply ml-1.5 md:ml-7;
  }

  /* social embeds */
  .instagram-media-rendered,
  .twitter-tweet-rendered,
  [title="Embedded post"] {
    @apply mx-auto max-w-full !important;
  }

  .tiktok-embed {
    @apply max-w-full px-0 !important;
  }

  /* swiper */

  .swiper-pagination-bullet-active-product {
    @apply !bg-pr-blue-900 !opacity-100;
  }

  .swiper-pagination-bullet-product {
    @apply block h-2.5 w-2.5 rounded-full bg-pr-light-gray-400 opacity-50 transition hover:bg-pr-blue-900 hover:opacity-100 md:h-3 md:w-3;
  }

  .swiper-cards > .swiper-wrapper {
    @apply xl:w-auto xl:!transform-none xl:justify-center xl:px-6 4xl:px-2;
  }

  .swiper-cards-grid > .swiper-wrapper {
    @apply lg:grid lg:grid-cols-3 lg:gap-y-10;
  }

  /* cards */

  .strikethrough-card {
    @apply relative px-1.5;
  }

  .strikethrough-card::before {
    content: "";
    transform: skewY(-15deg);

    @apply absolute inset-x-0 top-1/2 border border-pr-red-500;
  }

  .card-details {
    @apply type-pr-eta md:type-pr-theta text-pr-dark-gray-400;
  }

  .card-details-price b,
  .card-details-price strong {
    @apply type-pr-alpha lg:type-pr-beta mb-2 inline-block !text-[51px] text-pr-dark-gray-500;
  }

  .card-details p {
    @apply my-4 text-sm lg:my-5;
  }

  .card-details-price p {
    @apply my-0;
  }

  .card-details p:first-child {
    @apply mt-0;
  }

  .card-details-price p:first-child {
    @apply mt-0 text-[15px] font-bold text-pr-dark-gray-500;
  }

  .card-details p:last-child {
    @apply mb-0;
  }

  .card-details ul {
    @apply space-y-4 lg:space-y-5;
  }

  .card-details u {
    @apply border-b border-dashed border-pr-dark-gray-300 no-underline;
  }

  .card-details a {
    @apply link-underline-animated-sm;
  }

  .card-details ul {
    @apply pl-5;
  }

  .card-details ul li {
    @apply pr-5;

    list-style-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.17728L5.48776 8.66504L12.6294 1.52344' stroke='%23F15541' stroke-width='2.5' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E");
  }

  .card-details-columns ul {
    @apply pl-5 md:columns-2;
  }

  .card-details-columns ul li {
    @apply pr-5;

    list-style-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.17728L5.48776 8.66504L12.6294 1.52344' stroke='%23F15541' stroke-width='2.5' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E");
  }

  .card-details-details b {
    @apply text-[18px] font-bold;
  }

  .card-details-details ul {
    @apply mt-3.5 space-y-2;
  }

  /* comparison table */

  .comparison-table table {
    @apply w-full;
  }

  .comparison-table.comparison-table-centered td,
  .comparison-table.comparison-table-centered th {
    @apply text-center;
  }

  .comparison-table table td:first-child {
    @apply min-w-[425px] pl-10 md:min-w-[466px];
  }

  .comparison-table table td {
    @apply type-pr-eta md:type-pr-theta min-w-[125px] text-pr-dark-gray-500 md:min-w-[175px];
  }

  .comparison-table th {
    @apply bg-white px-2 py-3.5 text-[21px] font-medium;
  }

  .comparison-table tr td {
    @apply px-6 py-3.5;
  }

  .comparison-table table tbody tr:first-child th {
    @apply py-8;
  }

  .comparison-table td,
  .comparison-table th {
    @apply border-l border-[#0000004f] text-left;
  }

  .comparison-table tr {
    @apply border-t border-[#0000004f];
  }

  .comparison-table tr:last-child {
    @apply border-b-0;
  }

  .comparison-table td:first-child,
  .comparison-table th:first-child {
    @apply border-l-0 pl-10 text-left;
  }

  .comparison-table u {
    @apply border-b border-dashed border-pr-dark-gray-300 no-underline;
  }

  .comparison-table a b {
    @apply pr-button pr-button-lg pr-button-black mb-5 font-normal;
  }

  .comparison-table a {
    @apply !bg-none pt-3.5 text-pr-red-500;
  }

  .comparison-table table tr {
    @apply bg-pr-light-gray-100;
  }

  .comparison-table table tr:nth-child(2n + 3) {
    @apply bg-white;
  }

  .comparison-table table td:first-child {
    @apply min-w-[425px] md:min-w-[466px];
  }

  .comparison-table table td {
    @apply min-w-[125px] md:min-w-[175px];
  }

  /* comparison chart */

  .comparison-chart table {
    @apply w-full;
  }

  .comparison-chart table td {
    @apply type-pr-eta md:type-pr-theta w-[115px] min-w-[115px] max-w-[115px] py-2 text-pr-dark-gray-500;
  }

  .comparison-chart table td:first-child {
    @apply w-full min-w-[200px] max-w-none text-base text-pr-dark-gray-400;
  }

  .comparison-chart tr td:first-child {
    @apply pl-5;
  }
  .comparison-chart tr td:not(:first-child) p {
    @apply flex items-center justify-center;
  }

  .comparison-chart table tbody th {
    @apply hidden;
  }

  .comparison-chart td {
    @apply border-l border-gray-200 text-left;
  }

  .comparison-chart tr:not(:first-child) {
    @apply border-b border-gray-200;
  }

  .comparison-chart tr:last-child {
    @apply border-b-0;
  }

  .comparison-chart u {
    @apply border-b border-dashed border-pr-dark-gray-300 no-underline;
  }

  .comparison-chart a b {
    @apply pr-button pr-button-lg pr-button-black mb-5 font-normal;
  }

  .comparison-chart a {
    @apply !bg-none pt-3.5 text-pr-red-500;
  }

  .comparison-chart table tr {
    @apply bg-white;
  }

  .h-lvh {
    height: 100lvh;
  }

  /* .comparison-chart table td:first-child {
    @apply min-w-[425px] md:min-w-[466px];
  }

  .comparison-chart table td {
    @apply min-w-[125px] md:min-w-[175px];
  } */

  /* product site buttons */

  .pr-button {
    @apply inline-flex gap-3 rounded-full text-center text-pr-lg transition duration-200;
  }

  .pr-button-sm {
    @apply px-3 py-1;
  }

  .pr-button-lg {
    @apply px-5 py-3.5;
  }

  .pr-button-white {
    @apply bg-white text-pr-blue-600 hover:bg-pr-dark-gray-500 hover:text-white focus:bg-pr-dark-gray-500 focus:text-white;
  }

  .pr-button-black {
    @apply bg-pr-blue-900 text-white hover:bg-pr-dark-gray-500 focus:bg-pr-dark-gray-500;
  }

  .pr-button-dark-gray {
    @apply bg-pr-dark-gray-500 text-white hover:bg-pr-blue-900 focus:bg-pr-blue-900;
  }

  .pr-button-light-gray {
    @apply bg-pr-light-gray-100 text-pr-blue-600 hover:bg-pr-dark-gray-500 hover:text-white focus:bg-pr-dark-gray-500 focus:text-white;
  }

  .pr-button-green {
    @apply bg-pr-green-500 text-white hover:bg-pr-dark-gray-500 focus:bg-pr-dark-gray-500;
  }

  .pr-button-red {
    @apply bg-ada-salmon-500 text-white hover:bg-pr-dark-gray-500 focus:bg-pr-dark-gray-500;
  }

  .pr-button-teal {
    @apply bg-ada-teal-400 text-white hover:bg-pr-dark-gray-500 focus:bg-pr-dark-gray-500;
  }

  .pr-button-yellow {
    @apply bg-pr-yellow-500 text-white hover:bg-pr-dark-gray-500 focus:bg-pr-dark-gray-500;
  }

  /* headroom */
  /* .headroom {
    @apply fixed z-[1] top-0 left-0 right-0;
  }

  .headroom--unfixed {
    @apply relative translate-y-0;
  }

  .headroom--scrolled {
    @apply transition duration-200;
  }

  .headroom--unpinned {
    @apply fixed -translate-y-full;
  }

  .headroom--pinned {
    @apply fixed -translate-y-0;
  }

  .headroom--pinned.headroom--scrolled {
    @apply drop-shadow-md;
  } */

  .headroom--unpinned,
  .headroom--pinned {
    @apply drop-shadow-md;
  }

  .headroom--product-menu-active .headroom--alert,
  .headroom--blog-menu-active .headroom--alert {
    @apply hidden lg:block;
  }

  /* https://github.com/mdesjardins/react-a11y-select */
  .ReactA11ySelect {
    width: calc(100% + 2rem);
    @apply relative -mx-4 text-pr-lg md:mx-0 md:w-auto;
  }

  .ReactA11ySelect__button {
    @apply relative inline w-full py-2.5 pl-4 pr-9 text-left text-pr-red-500 sm:whitespace-nowrap md:py-5;
  }

  .ReactA11ySelect__button:hover {
    @apply cursor-pointer;
  }

  .ReactA11ySelect__button[aria-expanded="true"] {
    @apply z-[2];
  }

  .ReactA11ySelect__button__arrow-indicator {
    @apply absolute right-4 top-1/2 -translate-y-1/2 transition;
    content: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.945312 1.46094L5.22633 5.86605L9.44531 1.46094' stroke='%23F15541' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  }

  .ReactA11ySelect__button[aria-expanded="true"]
    .ReactA11ySelect__button__arrow-indicator {
    @apply rotate-180;
  }

  .ReactA11ySelect__ul {
    @apply absolute top-0 z-[1] w-full rounded-xl bg-white pb-4 pt-[42px] drop-shadow-lg md:w-auto md:pt-[62px];
  }

  .ReactA11ySelect__ul:not([aria-hidden="true"]) {
    @apply block;
  }

  .ReactA11ySelect__ul[aria-hidden="true"] {
    @apply hidden;
  }

  .ReactA11ySelect__ul__li {
    @apply relative cursor-pointer py-2.5 pl-4 pr-9 transition sm:whitespace-nowrap;
  }

  .ReactA11ySelect__ul__li[aria-checked="true"],
  .ReactA11ySelect__ul__li:hover {
    @apply bg-pr-red-100;
  }

  .ReactA11ySelect__ul__li[disabled] {
    @apply opacity-40;
  }

  /* pillar hero motifs */
  .redmotif {
    clip-path: url(#redmotif);
  }

  .greenmotif {
    clip-path: url(#greenmotif);
  }

  .yellowmotif {
    clip-path: url(#yellowmotif);
  }

  .tealmotif {
    clip-path: url(#tealmotif);
  }

  #hero-slider .swiper-slide {
    height: auto;
  }

  #hero-slider-buttons {
    --swiper-navigation-size: 16px;
    --swiper-theme-color: #30323b;
  }

  @media (max-width: 639px) {
    #hero-slider-buttons {
      --swiper-navigation-size: 10px;
    }
  }

  #hero-slider-buttons.swiper-buttons {
    @apply absolute right-[40px] top-[104px] z-10 -translate-y-1/2 xs:top-[142px] sm:top-[164px] md:right-[45px] md:top-1/2 lg:right-[58px] 2xl:right-[121px];
  }

  #hero-slider-buttons .swiper-button-next {
    @apply right-0 -rotate-90;

    top: calc(50% - var(--swiper-navigation-size) - 0.5rem);
  }

  #hero-slider-buttons .swiper-button-prev {
    @apply left-auto right-0 -rotate-90;

    top: calc(50% + var(--swiper-navigation-size));
  }

  #hero-slider-buttons .swiper-button-next::after {
    transform: translateX(1px);
  }

  #hero-slider-buttons .swiper-button-prev::after {
    transform: translateX(-1px);
  }

  #filter-navigation {
    --swiper-navigation-size: 24px;
    --swiper-theme-color: #30323b;
  }

  #filter-navigation .swiper-button-next,
  #filter-navigation .swiper-button-prev {
    transform: translateY(calc(-100% + (var(--swiper-navigation-size) / 3)));
  }

  .dynamic-trend-swiper.swiper-pagination-top .swiper-pagination {
    @apply bottom-auto top-4;
  }

  .swiper-pagination-bullet-dynamic-trend {
    @apply mx-1 inline-flex w-fit rounded-full p-2 transition delay-75 duration-200;
  }

  .swiper-pagination-bullet-dynamic-trend-teal {
    @apply swiper-pagination-bullet-dynamic-trend bg-pr-teal-200/50 focus:bg-pr-teal-500;
  }

  .swiper-pagination-bullet-dynamic-trend-green {
    @apply swiper-pagination-bullet-dynamic-trend bg-pr-green-500/50 focus:bg-green-600;
  }

  .swiper-pagination-bullet-dynamic-trend-red {
    @apply swiper-pagination-bullet-dynamic-trend bg-red-600/50 focus:bg-red-800;
  }

  .swiper-pagination-bullet-active-dynamic-trend-teal {
    @apply bg-pr-teal-200 focus:bg-pr-teal-500;
  }

  .swiper-pagination-bullet-active-dynamic-trend-green {
    @apply bg-pr-green-500 focus:bg-green-600;
  }

  .swiper-pagination-bullet-active-dynamic-trend-red {
    @apply bg-red-600 focus:bg-red-800;
  }

  .swiper-pagination-bullet-trend {
    @apply inline-flex w-fit justify-center gap-3 rounded-full px-5 py-3.5 text-center text-pr-lg font-bold text-[#30323b] transition delay-75 duration-200;
  }

  .swiper-pagination-bullet-trend-teal {
    @apply swiper-pagination-bullet-trend bg-[#B1DFE2] focus:bg-[#B1DFE2];
  }

  .swiper-pagination-bullet-trend-red {
    @apply swiper-pagination-bullet-trend bg-[#e2bab1] focus:bg-[#e2bab1];
  }

  .swiper-pagination-bullet-trend-yellow {
    @apply swiper-pagination-bullet-trend bg-[#e2d9b1] focus:bg-[#e2d7b1];
  }

  .swiper-pagination-bullet-trend-green {
    @apply swiper-pagination-bullet-trend bg-[#b1e2c2] focus:bg-[#b1e2c2];
  }

  .swiper-pagination-bullet-active-trend-teal {
    @apply bg-pr-teal-400;
  }

  .swiper-pagination-bullet-salmon-outline {
    @apply swiper-pagination-bullet-trend border border-pr-yellow-200 bg-ada-salmon-500 text-white hover:border-pr-dark-gray-600 hover:bg-pr-dark-gray-600;
  }
  .swiper-pagination-bullet-salmon-outline--active {
    @apply swiper-pagination-bullet-trend border-pr-dark-gray-600 bg-pr-dark-gray-600 text-white;
  }

  .swiper-pagination-bullet-active-trend-red {
    @apply bg-pr-red-400;
  }

  .swiper-pagination-bullet-active-trend-yellow {
    @apply bg-pr-yellow-400;
  }

  .swiper-pagination-bullet-active-trend-green {
    @apply bg-pr-green-500;
  }

  .swiper-pagination-pricing {
    @apply mx-auto inline-flex w-[50%] justify-center bg-pr-yellow-200 px-10 py-6 text-center text-[24px] font-bold text-[#30323b] transition duration-200 lg:px-0 lg:py-[30px] lg:text-pr-3xl;
  }

  .fade-section {
    opacity: 0;
    /* transform: translate(0, 15%); */
    visibility: hidden;
    transition: opacity 800ms ease-out, transform 800ms ease-out;
    will-change: opacity, visibility;
  }

  .is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: flex;
  }

  .jumptarget::before {
    content: "";
    display: block;
    height: 200px; /* fixed header height*/
    margin: -200px 0 0; /* negative fixed header height */
  }

  .pointer:after {
    content: "";
    position: absolute;
    border: 20px solid;
    border-color: white transparent transparent;
    top: -35px;
    transform: rotate(180deg);
  }

  .clip-path {
    clip-path: inset(-15px 0px 0px -15px);
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
  }

  .corner {
    width: 0;
    height: 0;
    border-top: 85px solid #fec241;
    border-bottom: 85px solid transparent;
    border-left: 85px solid transparent;
    position: absolute;
    right: 0;
  }

  .corner span {
    position: absolute;
    top: -80px;
    width: 60px;
    left: -65px;
    text-align: center;
    font-size: 16px;
    font-family: arial;
    display: block;
  }

  .pricing-shadow {
    filter: drop-shadow(0px 0px 6.615px rgba(0, 0, 0, 0.15))
      drop-shadow(0px 0px 33.077px rgba(0, 0, 0, 0.15));
  }
  /* 
  .pricing-table > div > table{
    @apply mx-auto w-full lg:w-[250px];
  } */

  .pricing-table > div > table > tbody {
    @apply divide-y-[1px] divide-[#B0B0B0];
  }

  .pricing-table > div > table > tbody > tr {
    @apply flex flex-row justify-between;
  }

  .pricing-table > div > table > tbody > tr > td:first-child {
    @apply pb-[18px] text-pr-base font-bold;
  }

  .pricing-table > div > table > tbody > tr > td {
    @apply py-[18px] text-xl;
  }

  .pricing-table > div > table > tbody > tr > td:last-child {
    @apply pt-[18px] text-xl;
  }

  .pricing-table > div > table > tbody > tr > td > p:nth-child(2) {
    @apply ml-5 pt-[16px] text-2xs font-normal leading-6 lg:ml-10;
  }
}

/* stylelint-enable */
