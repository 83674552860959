@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./base/webfonts.css";
@import "./base/typography.css";
@import "./base/components.css";
@import "./base/extensions.css";
@import "./base/forms.css";
@import "./base/toad-calculator.css";
@import "./base/bentocore.css";

html {
  @apply scroll-pt-28 scroll-smooth md:scroll-pt-36;
}
